import { ref } from 'vue';
import { useRuntimeConfig } from '#imports';
import { useCurrentUser } from '@/composables/use-auth';
import { formatPersonName } from '@/composables/use-formatting';

export function initializeJitsi (opts) {
  const domain = opts?.domain || 'meet.jit.si';
  const scriptSrc = `https://${domain}/external_api.js`;

  return new Promise(resolve => {
    if (initializeJitsi._loaded) return resolve();
    initializeJitsi._loaded = true;
    const d = globalThis.document;
    const scriptEl = d.createElement('script');
    scriptEl.src = scriptSrc;
    scriptEl.async = 1;
    d.querySelector('head').appendChild(scriptEl);
    scriptEl.addEventListener('load', () => {
      console.log(`Jitsi embed loaded from ${scriptSrc}`);
      return resolve();
    });
  });
}

export function getSdk () {
  if (!globalThis.window.JitsiMeetExternalAPI) {
    throw new Error('JitsiMeetExternalAPI is not available');
  }
  return globalThis.window.JitsiMeetExternalAPI;
}

export function useJitsi (opts) {
  const runtimeConfig = useRuntimeConfig();
  console.warn('runtimeConfig', runtimeConfig)
  opts = Object.assign({
    domain: runtimeConfig.public?.jitsiDomain || 'meet.jit.si',
    selector: '#jitsi-mount',
  }, opts);

  const currentUser = useCurrentUser();

  const api = ref(null);

  const endJitsiCall = async () => {
    if (!api.value) return;
    api.value.dispose();
    api.value = null;
  };

  const startJitsiCall = async (roomName, copts) => {
    if (!roomName) throw new Error('roomName is required');
    copts = Object.assign({
      appName: 'Parmazip',
      watermarkLink: 'https://parmazip.ca',
    }, copts);
    copts.selector ??= opts.selector;
    copts.domain ??= opts.domain;
    if (!copts.mountPoint) {
      copts.mountPoint = globalThis.document.querySelector(copts.selector);
    }
    await endJitsiCall();
    const callopts = { 
      roomName,
      parentNode: copts.mountPoint,
      configOverwrite: {
        disableModeratorIndicator: true,
        toolbarButtons: [
          'microphone', 
          'camera', 
          'chat',
          'tileview',
          'hangup',
        ],
      },
      interfaceConfigOverwrite: {
        APP_NAME: copts.appName,
        DISPLAY_WELCOME_PAGE_CONTENT: true,
        JITSI_WATERMARK_LINK: copts.watermarkLink,
        SETTINGS_SECTIONS: [],
      },
    };
    if (currentUser.value?.uid) {
      callopts.userInfo = {
        displayName: formatPersonName(currentUser.value.name),
        email: currentUser.value.email,
      };
    }
    console.debug('Jitsi call options', copts.domain, callopts);
    const Sdk = getSdk();
    api.value = new Sdk(copts.domain, callopts);
    if (currentUser.value?.picURL) {
      api.value.executeCommand('avatarUrl', currentUser.value.picURL);
    }
    api.value.executeCommand('toggleAudio');
    api.value.executeCommand('localSubject', 'Parmazip Virtual Consultation');

    // handle events
    const events = copts.events || {};
    for (const [event, handler] of Object.entries(events)) {
      api.value.addListener(event, handler);
    }
  };

  return {
    api,
    startJitsiCall,
    endJitsiCall,
  };
}
